<template>
  <v-card
    :class="{
      'card--flex-toolbar-xs': $vuetify.breakpoint.xsOnly,
      'card--flex-toolbar': $vuetify.breakpoint.smAndUp
    }"
  >
    <v-toolbar flat dense class="elevation-3" color="secondary">
      <v-btn small fab dark color="info" @click="$router.go(-1)" class="mx-1">
        <v-icon dark>arrow_back_ios</v-icon>
      </v-btn>
      <v-toolbar-title class="body-2 white--text">{{
        this.$route.meta.subtitle
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small fab dark class="mx-1" color="primary" @click="reload">
        <v-icon dark>autorenew</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }">
      <v-container
        fluid
        fill-height
        class="pt-0"
        :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }"
      >
        <v-row no-gutters align-space-around justify-center>
          <v-col cols="12">
            <InteractiveTable
              ref="boardUserRoleList"
              class="lg-table"
              :headers="[
                { text: 'ID', value: 'id' },
                { text: 'Ime', value: 'first_name' },
                { text: 'Prezime', value: 'last_name' },
                { text: 'Funkcija', value: 'role_name' },
                { text: 'Vrijedi od', value: 'valid_from' },
                { text: 'Vrijedi do', value: 'valid_to' },
                { text: 'Napomena', value: 'note' }
              ]"
              :apiLoad="'board/boardusers.php?board=' + $route.params.id"
              :actions="[
                {
                  id: '1',
                  name: 'Uredi korisnika',
                  path: '/useredit/#user#',
                  icon: 'edit',
                  color: 'secondary'
                }
              ]"
              defaultFilter="last_name"
              :queryFilter="this.$route.params"
              dateFilter
              defaultSort="last_name"
              :pageSize="50"
            ></InteractiveTable>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import InteractiveTable from "@/components/InteractiveTable.vue";

export default {
  name: "BoardUserRoleList",
  data() {
    return {
      date: null,
      dateVisible: false
    };
  },
  components: {
    InteractiveTable
  },
  computed: {
    needsRefresh: function() {
      return this.$store.getters.boardUserRoleListNeedsRefresh;
    }
  },
  watch: {
    needsRefresh(needsRefresh) {
      if (needsRefresh) {
        this.reload();
      }
    }
  },
  methods: {
    reload() {
      this.$refs.boardUserRoleList.reload();
      this.$store.dispatch("boarduserrole_list_refreshed");
    }
  }
};
</script>
